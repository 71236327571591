import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-apollo'
import { StripeProvider } from 'react-stripe-elements'

import SEO from '../components/global/SEO'
import Layout from '../components/Layout'
import { Section } from '../components/global/Section'
import { H2 } from '../components/global/Headings'

import { GET_CART } from '../components/cart/queries'
import CheckoutWrapper from '../components/checkout/CheckoutWrapper';
import { Redirect } from '@reach/router';

const Checkout = () => {
  const [stripe, setStripe] = useState(null)
  const { data } = useQuery(GET_CART)

  useEffect(() => {
    if (window.Stripe) {
      setStripe(window.Stripe(process.env.GATSBY_STRIPE_PUBLIC, {
        stripeAccount: process.env.GATSBY_CONNECTED_STRIPE_ACCOUNT
      }));
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        setStripe(window.Stripe(process.env.GATSBY_STRIPE_PUBLIC, {
          stripeAccount: process.env.GATSBY_CONNECTED_STRIPE_ACCOUNT
        }));
      });
    }
  }, [])

  if ((data.cart.items.length === 0) && data.status.code !== 'LOADING') {
    return <Redirect to='/cart' noThrow />
  }
  return (
    <Layout>
      <SEO 
        title="Checkout • Coach Tours, Giants Causeway, Belfast • Tours Ireland"
      />
      <Section>
        <H2 icon>Checkout</H2>
        {stripe && 
          <StripeProvider stripe={stripe}>
            <CheckoutWrapper cart={data.cart} status={data.status} />
          </StripeProvider>
        }
      </Section>
    </Layout>
  )
}

export default Checkout