import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { PaymentRequestButtonElement, injectStripe } from 'react-stripe-elements'
import { withApollo } from "react-apollo";
import { CREATE_ORDER_MUTATION, EMPTY_CART, GET_CART } from "../cart/queries";
import { navigate } from "@reach/router";
import { SET_STATUS, GET_STATUS } from "../global/queries";

const PaymentRequestForm = ({ clientSecret, cart, stripe, client }) => {
  const [request, setRequest] = useState(null)
  const [canMakePayment, setCanMakePayment] = useState(null)

  useEffect(() => {
    initPaymentRequest()
  }, [])

  const initPaymentRequest = async () => {
    const paymentRequest = await stripe.paymentRequest({
      country: 'GB',
      currency: 'gbp',
      total: {
        label: 'Total',
        amount: cart.subtotal * 100,
      },
      displayItems: cart.items.map(item => {
        return {
          label: item.title,
          amount: item.subtotal * 100
        }
      }),
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true
    });

    paymentRequest.on('token', ({ complete, token, ...data }) => {
      complete('success');
    });

    paymentRequest.canMakePayment().then((result) => {
      setCanMakePayment(!!result)
    });

    paymentRequest.on('paymentmethod', async (ev) => {
      client.mutate({
        mutation: SET_STATUS,
        variables: {
          __typename: 'Status',
          code: 'LOADING',
          message: null
        },
        refetchQueries: [{
          query: GET_STATUS
        }]
      })

      const { paymentIntent, error: confirmError } = await stripe.confirmPaymentIntent(clientSecret, {
        payment_method: ev.paymentMethod.id
      });

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        client.mutate({
          mutation: SET_STATUS,
          variables: {
            __typename: 'Status',
            code: 'ERROR',
            message: confirmError.message
          },
          refetchQueries: [{
            query: GET_STATUS
          }]
        })
        navigate('#messages')
        ev.complete('fail');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Let Stripe.js handle the rest of the payment flow.
        try {
          const { data, errors } = await client.mutate({
            mutation: CREATE_ORDER_MUTATION,
            variables: {
              email: ev.payerEmail,
              name: ev.payerName,
              phone: ev.payerPhone,
              bookings: cart.items.map(({id, tickets}) => {
                return {
                  id,
                  tickets: tickets.map(({ title, cost, quantity}) => {
                    return { title, cost, quantity }
                  })
                }
              }),
              amount: cart.subtotal * 100,
              intent: paymentIntent.id
            }
          })
          if (errors) {
            client.mutate({
              mutation: SET_STATUS,
              variables: {
                __typename: 'Status',
                code: 'ERROR',
                message: 'Error connecting to server.'
              },
              refetchQueries: [{
                query: GET_STATUS
              }]
            })
            navigate('#messages')
          } else {
            await client.mutate({
              mutation: EMPTY_CART,
              refetchQueries: [{
                query: GET_CART
              }]
            })
            window.localStorage.removeItem('intent')
            navigate(`/order/${data.createOrder.id}`)
          }
        } catch (error) {
          client.mutate({
            mutation: SET_STATUS,
            variables: {
              __typename: 'Status',
              code: 'ERROR',
              message: 'Error connecting to server.'
            },
            refetchQueries: [{
              query: GET_STATUS
            }]
          })
          navigate('#messages')
        }
      }
    });
    setRequest(paymentRequest)
  }

  return canMakePayment ? (
    <div className="mx-auto sm:w-3/5">
      <PaymentRequestButtonElement
        paymentRequest={request}
      />
      <p className="text-center text-xs text-gray-300 mt-4 w-3/5 mx-auto">Checkout quickly and securely with Apple Pay, Google Pay or Microsoft Pay.</p>
      <div className="border-b border-gray-200 my-10 w-2/5 mx-auto relative">
        <span className="block text-gray-300 bg-white w-10 mx-auto -m-3 text-center">OR</span>
      </div>
    </div>
  ) : null;
}

PaymentRequestForm.propTypes = {
  clientSecret: PropTypes.string.isRequired,
  cart: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired
}

export default withApollo(injectStripe(PaymentRequestForm))