import React, { useState } from 'react'
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements'
import { Form, Field } from 'react-final-form'
import { withApollo } from 'react-apollo'
import { navigate } from '@reach/router'
import { Link } from 'gatsby';

import { CREATE_ORDER_MUTATION, GET_CART, EMPTY_CART } from '../cart/queries'
import { SET_STATUS, GET_STATUS } from '../global/queries';

const stripeInputStyles = {
  base: {
    fontSize: '16px',
    '::placeholder': {
      color: '#959a9c',
    },
  },
  invalid: {
    color: '#272c2f'
  }
}

const INIT_CARD_ERRORS = {
  cardNumber: {},
  cardExpiry: {},
  cardCVC: {}
}

const CheckoutForm = ({ cart, clientSecret, status, stripe, client }) => {
  const [cardErrors, setCardErrors] = useState(INIT_CARD_ERRORS);  
  
  const updateErrorStatus = ({ elementType, error, empty }) => {
    setCardErrors({
      ...cardErrors,
      [elementType]: {
        error,
        empty
      }
    })
  }

  const submit = async values => {
    client.mutate({
      mutation: SET_STATUS,
      variables: {
        __typename: 'Status',
        code: 'LOADING',
        message: null
      },
      refetchQueries: [{
        query: GET_STATUS
      }]
    })

    const { paymentIntent, error } = await stripe.handleCardPayment(clientSecret, {
      payment_method_data: {
        billing_details: {
          name: values.name,
          email: values.email,
          phone: values.phone
        }
      }
    });

    if (!error) {
      try {
        const { data } = await client.mutate({
          mutation: CREATE_ORDER_MUTATION,
          variables: {
            email: values.email,
            name: values.name,
            phone: values.tel,
            bookings: cart.items.map(({id, tickets}) => {
              return {
                id,
                tickets: tickets.map(({ title, cost, quantity}) => {
                  return { title, cost, quantity }
                })
              }
            }),
            amount: cart.subtotal * 100,
            intent: paymentIntent.id
          }
        })
        await client.mutate({
          mutation: EMPTY_CART,
          refetchQueries: [{
            query: GET_CART
          }]
        })
        window.localStorage.removeItem('intent')
        navigate(`/order/${data.createOrder.id}`)
      } catch (error) {
        client.mutate({
          mutation: SET_STATUS,
          variables: {
            __typename: 'Status',
            code: 'ERROR',
            message: error.message
          },
          refetchQueries: [{
            query: GET_STATUS
          }]
        })
        navigate('#messages')
      }
      
    } else {
      client.mutate({
        mutation: SET_STATUS,
        variables: {
          __typename: 'Status',
          code: 'ERROR',
          message: error.message
        },
        refetchQueries: [{
          query: GET_STATUS
        }]
      })
      navigate('#messages')
    }
  }

  return (
    <Form
      onSubmit={submit}
      validate={values => {
        const errors = {};
        if (!values.name) {
          errors.name = "Required";
        }
        if (!values.email) {
          errors.email = "Required";
        }
        if (!values.confirmEmail) {
          errors.email = "Required";
        }
        if (values.confirmEmail !== values.email) {
          errors.email = "Required";
        }
        if (!values.tel) {
          errors.tel = "Required";
        }
        return errors;
      }}
      render={({ handleSubmit, pristine, invalid }) => (
        <form className="CheckoutForm" onSubmit={handleSubmit}>
          <h2 className="text-2xl mb-8">1. Booking Details</h2>
          <div className="flex flex-wrap mb-6">
            <div className="w-full sm:w-1/2 sm:pr-3 mb-6">
              <Field name="email">
                {({ input, meta }) => (
                  <>
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="email">Email</label>
                    <input
                      {...input}
                      type="email"
                      className={`appearance-none block w-full text-gray-700 border ${meta.error && meta.touched ? "border-red-500" : "border-gray-300"} rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                      id="email"
                      placeholder="Email Address" />
                    {meta.error && meta.touched && <p className="text-red-500 text-xs italic">{meta.error}</p>}
                  </>
                )}
              </Field>
            </div>
            <div className="w-full sm:w-1/2 sm:pl-3 mb-6">
              <Field name="confirmEmail">
                {({ input, meta }) => (
                  <>
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="confirmEmail">Confirm Email</label>
                    <input
                      {...input}
                      type="email"
                      className={`appearance-none block w-full text-gray-700 border ${meta.error && meta.touched ? "border-red-500" : "border-gray-300"} rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                      id="confirmEmail"
                      placeholder="Confirm Email Address" />
                    {meta.error && meta.touched && <p className="text-red-500 text-xs italic">{meta.error}</p>}
                  </>
                )}
              </Field>
            </div>
            <div className="w-full sm:w-1/2 sm:pr-3 mb-6">
              <Field name="name">
                {({ input, meta }) => (
                  <>
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="name">Name</label>
                    <input
                      {...input}
                      type="text"
                      className={`appearance-none block w-full text-gray-700 border ${meta.error && meta.touched ? "border-red-500" : "border-gray-300"} rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                      id="name"
                      placeholder="Name" />
                    {meta.error && meta.touched && <p className="text-red-500 text-xs italic">{meta.error}</p>}
                  </>
                )}
              </Field>
            </div>
            <div className="w-full sm:w-1/2 sm:pl-3 mb-6">
              <Field name="tel">
                {({ input, meta }) => (
                  <>
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="phone">Phone</label>
                    <input
                      {...input}
                      type="tel"
                      className={`appearance-none block w-full text-gray-700 border ${meta.error && meta.touched ? "border-red-500" : "border-gray-300"} rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                      id="tel"
                      placeholder="Phone Number" />
                    {meta.error && meta.touched && <p className="text-red-500 text-xs italic">{meta.error}</p>}
                  </>
                )}
              </Field>
            </div>
          </div>
          <h2 className="text-2xl mb-8">2. Payment Details</h2>
          <div className="flex flex-wrap">
            <div className="w-full sm:w-1/2 sm:pr-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="cardNumber">Card Number</label>
              <CardNumberElement
                id="cardNumber"
                className={`text-base appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                style={stripeInputStyles}
                onChange={e => updateErrorStatus(e)}
              />
              {cardErrors.cardNumber.error && <p className="text-red-500 text-xs italic">{cardErrors.cardNumber.error.message}</p>}
            </div>
            <div className="w-1/2 sm:w-1/4 pr-3 sm:px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="cardExpiry">Expiry</label>
              <CardExpiryElement
                id="cardExpiry"
                className={`text-base appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                style={stripeInputStyles}
                onChange={e => updateErrorStatus(e)}
              />
              {cardErrors.cardExpiry.error && <p className="text-red-500 text-xs italic">{cardErrors.cardExpiry.error.message}</p>}
            </div>
            <div className="w-1/2 sm:w-1/4 pl-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="cardCVC">CVC</label>
              <CardCVCElement
                id="cardCVC"
                className={`text-base appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                style={stripeInputStyles}
                onChange={e => updateErrorStatus(e)}
              />
              {cardErrors.cardCVC.error && <p className="text-red-500 text-xs italic">{cardErrors.cardCVC.error.message}</p>}
            </div>
          </div>
          <div className="flex justify-between sm:justify-end mt-2 mb-8">
            <span className="mr-16 font-bold">Order Total:</span>
            <span>{`£${cart.subtotal.toFixed(2)}`}</span>
          </div>
          <div className="flex flex-col sm:flex-row-reverse sm:justify-between sm:items-center">
            <button
              className="w-full sm:w-auto btn btn-green mb-12 sm:mb-0 block text-center sm:inline-block"
              type="submit"
              disabled={status.code === 'LOADING'}
            >
              {status.code === 'LOADING' ? 'Submitting' : 'Confirm Order'}
            </button>
            <Link to="/cart" className="no-underline text-green-500 font-bold lg:w-1/2">← Return to Cart</Link>
          </div>
        </form>
      )}
    />
  );
}

export default withApollo(injectStripe(CheckoutForm));