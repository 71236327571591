import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements'
import { withApollo } from 'react-apollo';

import CheckoutForm from './CheckoutForm'
import PaymentRequestForm from './PaymentRequestForm';
import { INIT_ORDER_MUTATION } from '../cart/queries';
import Loader from '../global/Loader';

import { hasExpiredCartItems } from '../../utils/helpers';
import { useStatus } from '../global/customHooks';

const CheckoutWrapper = ({ cart, status, client }) => {
  const { setError } = useStatus()
  const [loading, setLoading] = useState(true)
  const [retryCount, setRetryCount] = useState(0)
  
  const [intent, setIntent] = useState({
    id: (typeof window !== undefined) ? JSON.parse(window.localStorage.getItem('intent')) : null,
    clientSecret: null
  })
  
  useEffect(() => {
    if (!hasExpiredCartItems(cart, client)) {
      initOrder()
    }
  }, [])

  useEffect(() => {
    if (intent && intent.id) {
      (typeof window !== undefined) && window.localStorage.setItem('intent', JSON.stringify(intent.id))
    } else {
      initOrder()
    }
  }, [intent])

  const initOrder = async () => {
    if (retryCount < 2) {
      const { data, errors } = await client.mutate({
        mutation: INIT_ORDER_MUTATION,
        variables: {
          amount: cart.subtotal * 100,
          intent: intent.id || null
        }
      });
      if (!errors) {
        setIntent(data.initOrder)
        setLoading(false)
      } else {
        if (retryCount === 0) {
          window.localStorage.removeItem('intent')
          setIntent({
            id: null,
            clientSecret: null
          })
          setRetryCount(1)
        } else {
          setRetryCount(2)
          setError('The server encountered an error processing your request.')
        }
      } 
    }
  }

  if (loading) return <Loader />

  return (
    <div className="mx-auto md:w-4/5 lg:w-3/5">
      <div className="w-full relative">
        {status.code === 'LOADING' && <div className="absolute w-full h-full bg-opaque-white-70 top-0 left-0 flex items-center justify-center z-50"><Loader /></div>}
        {intent && intent.clientSecret &&
          <>
            <Elements>
              <PaymentRequestForm cart={cart} clientSecret={intent.clientSecret} />
            </Elements>
            <Elements>
              <CheckoutForm cart={cart} clientSecret={intent.clientSecret} status={status} />
            </Elements>
          </>
        }
      </div>
    </div>
  )
}

CheckoutWrapper.propTypes = {
  cart: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired
}

export default withApollo(CheckoutWrapper)